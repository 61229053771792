import React from 'react'
import schedp1 from '../images/schedule/schedp1.jpg'
import schedp2 from '../images/schedule/schedp2.jpg'

const scheduleStyle = {
  backgroundColor: 'white',
  width: '100%',
  textAlign: 'center',
}

const imgStyle = {
  maxWidth: '100%',
  height: 'auto',
}

export default Schedule => (
  <div style={scheduleStyle}>
    <img style={imgStyle} src={schedp1} alt="" />
    <img style={imgStyle} src={schedp2} alt="" />
  </div>
)
